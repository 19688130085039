








































































































import type { PropType } from 'vue';
import { DateTime } from 'luxon';
import { VMenu, VDialog } from 'vuetify/lib/components';
import { isObject, isString } from 'lodash';
import DatePicker from 'vue2-datepicker';
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';

import '@/styles/vue2-datepicker.scss';

const timeRegExp = /^(?:[01]\d|2[0-3]):[0-5]\d$/;

type TRangeValue = {
  from: string;
  to: string;
};

type TTypeDatePicker = 'date' | 'year' | 'month' | undefined;

export default {
  name: 'ExtendedDatePicker',
  components: { DatePicker, VueTimepicker, VMenu, VDialog },

  props: {
    value: {
      type: [String, Object as PropType<TRangeValue>],
      default: null
    },
    type: {
      type: String as PropType<TTypeDatePicker>,
      default: 'date'
    },
    buttonProps: {
      type: Object,
      default: (): any => {
        return {};
      }
    },
    menuProps: {
      type: Object,
      default: (): any => {
        return {};
      }
    },
    dialogProps: {
      type: Object,
      default: (): any => ({})
    },
    showPreset: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    displayFormat: {
      type: String,
      default: 'dd MMMM'
    },
    minToday: {
      type: Boolean,
      default: false
    },
    minTomorrow: {
      type: Boolean,
      default: false
    },
    maxToday: {
      type: Boolean,
      default: false
    },
    maxYesterday: {
      type: Boolean,
      default: false
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    },
    maxDays: {
      type: Number,
      default: null
    },
    range: {
      type: Boolean,
      default: false
    },
    isDialog: {
      type: Boolean,
      default: false
    },
    timeSelect: {
      type: Boolean,
      default: false
    },
    showReset: {
      type: Boolean,
      default: false
    }
  },

  data(): any {
    return {
      open: false,
      date: null,
      time: '',
      from: null,
      to: null,
      selectTime: false,
      durationValid: false,
      selectedBoth: true
    };
  },

  computed: {
    containerComponent(): { component: string; props: any } {
      if (this.isDialog) {
        return {
          component: 'VDialog',
          props: {
            contentClass: 'extended-date-picker-dialog',
            ...this.dialogProps
          }
        };
      }

      return {
        component: 'VMenu',
        props: {
          closeOnContentClick: false,
          nudgeBottom: 20,
          offsetY: true,
          left: true,
          minWidth: 'auto',
          ...this.menuProps
        }
      };
    },

    nowDate(): DateTime {
      return DateTime.now();
    },

    modelValue: {
      get(): string[] | string {
        if (this.range) {
          if (this.validateDate(this.from) && this.validateDate(this.to)) {
            return [this.from, this.to];
          }
        }

        return this.date;
      },
      set(value: string[]): void {
        if (this.range) {
          const [from, to] = value;

          this.from = from;
          this.to = to;
        } else {
          this.date = value;
        }
      }
    },

    showInput(): boolean {
      return this.type === 'date' && this.range;
    },

    shortcuts(): any {
      if (!this.showPreset && !this.showInput) return [];

      const now = this.nowDate;
      const today = this.nowDate.toISO();
      const to = today > this.maxDate ? this.maxDate : today;

      const preset = [
        {
          text: 'Today',
          range: [today, today]
        },
        {
          text: 'Yesterday',
          range: [
            now.minus({ day: 1 }).toISO(),
            now.minus({ day: 1 }).toISO()
          ]
        },
        {
          text: 'This month',
          range: [now.startOf('month').toISO(), to]
        },
        {
          text: 'Last month',
          range: [now.minus({ month: 1 }).toISO(), to]
        },
        {
          text: 'Last 3 month',
          range: [now.minus({ month: 3 }).toISO(), to]
        },
        {
          text: 'Last 12 month',
          range: [now.minus({ month: 12 }).toISO(), to]
        }
      ];

      return preset.filter(this.filterShortcuts).map((el) => ({
        text: el.text,
        onClick: () => el.range.map(this.JSDateFromISO)
      }));
    },

    emptyValue(): boolean {
      return isObject(this.value)
        ? !this.value.from && !this.value.to
        : !this.value;
    },

    displayDate(): string {
      const format = (d: string) =>
        DateTime.fromISO(d).toFormat(this.displayFormat);

      if (this.emptyValue) return 'Select Date';

      if (this.timeSelect) return this.value;

      if (this.range) {
        if (this.value.from === this.value.to) {
          return format(this.value.from);
        }

        return [this.value.from, this.value.to].map(format).join(' - ');
      }

      return format(this.value);
    },

    minDate(): string | null {
      if (this.min) return this.min;

      if (this.minToday) return this.nowDate.minus({ day: 1 }).toISO();

      if (this.minTomorrow) {
        return this.nowDate.toISO();
      }
      return null;
    },

    maxDate(): string | null {
      if (this.max) return this.max;

      if (this.maxToday) return this.nowDate.toISO();

      if (this.maxYesterday) {
        return this.nowDate.minus({ day: 1 }).toISO();
      }

      return null;
    },

    inputPlaceholder(): string {
      return this.type === 'month' ? 'YYYY-MM' : 'YYYY-MM-DD';
    },

    rules(): any {
      if (!this.range) return;

      const date = (v: string) => this.validateDate(v) || 'Invalid date';

      return {
        from: [date],
        to: [date]
      };
    },

    validPeriod(): boolean {
      if (!this.maxDays) return true;

      const diffDays = this.getDiffDays(this.from, this.to);

      return diffDays <= this.maxDays;
    },

    validTime(): boolean {
      if (!this.timeSelect) return true;

      return timeRegExp.test(this.time);
    },

    periodErrorMessage(): string {
      return `Please choose a date range up to ${this.maxDays} days`;
    },

    validateDurationInRange(): boolean {
      if (this.showInput) return this.durationValid;

      return true;
    },

    disabledApplyButton(): boolean {
      const selected = this.range ? this.from && this.to : this.date;

      return (
        !this.validPeriod ||
        !this.validateDurationInRange ||
        !this.validTime ||
        !this.selectedBoth ||
        !selected
      );
    }
  },

  watch: {
    value: {
      handler: 'setInitialValue',
      immediate: true
    }
  },

  methods: {
    JSDateFromISO(date: string): Date {
      return DateTime.fromISO(date).toJSDate();
    },

    disallowedDates(d: Date): boolean {
      const date = DateTime.fromJSDate(d).toISO();

      if (this.maxDate && this.minDate) {
        return date < this.minDate || date > this.maxDate;
      }

      if (this.maxDate) return date > this.maxDate;

      if (this.minDate) return date < this.minDate;
    },

    getDiffDays(from: string, to: string): number {
      return DateTime.fromISO(to).diff(DateTime.fromISO(from)).as('days');
    },

    filterShortcuts(shortcut: { text: string; range: string[] }): boolean {
      if (!shortcut.range.length) return true;

      const [from, to] = shortcut.range;

      if (this.maxDays) {
        const period = this.getDiffDays(from, to) <= this.maxDays;

        if (this.maxDate) return period && to <= this.maxDate;

        return period;
      }

      if (this.minDate && this.maxDate) {
        return from >= this.minDate && to <= this.maxDate;
      }

      if (this.minDate) return from >= this.minDate;

      if (this.maxDate) return to <= this.maxDate;

      return true;
    },

    validateDate(date: string): boolean {
      if (
        (date && date.length !== 10) ||
        date > this.maxDate ||
        date < this.minDate ||
        this.to < this.from ||
        this.from > this.to
      ) {
        return false;
      }

      return DateTime.fromISO(date).isValid;
    },

    setInitialValue(value: TRangeValue | string): void {
      if (this.timeSelect && isString(value)) {
        const [date, time] = value.split(' ');

        this.modelValue = date;
        this.time = time;

        return;
      }

      this.modelValue = isObject(value) ? Object.values(value) : value;
    },

    updateValue(): void {
      const { from, to, date, time } = this;

      if (this.range) {
        this.$emit('input', { from, to });
      } else {
        if (this.timeSelect) {
          this.$emit('input', `${date} ${time}`);
        } else {
          this.$emit('input', date);
        }
      }
    },

    reset(): void {
      this.from = null;
      this.to = null;
      this.date = null;
      this.time = '';

      if (this.showInput) {
        this.$refs.formDuration.resetValidation();
      }

      this.$nextTick(this.updateValue);
    },

    handleClickApply(): void {
      this.open = false;
      this.updateValue();
    },

    handleClickCancel(): void {
      this.setInitialValue(this.value);

      this.open = false;
    },

    handlerChangePick(): void {
      if (!this.range) return;

      this.selectedBoth = false;
    },

    setSelectedBoth(value: string[]): void {
      if (!this.range) return;

      this.$nextTick().then(() => {
        this.selectedBoth = value.length === 2;
      });
    }
  }
};
